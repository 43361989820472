<template>
  <v-sheet class="offre-wrapper" height="100%">
    <v-container class="" style="padding: 3.5rem 0">
      <h1 class="text-center display-3 font-weight-medium">Avantages</h1>
      <v-row style="margin-top: 5rem">
        <v-col cols="12" md="6">
          <div class="image-wrapper d-flex align-center d-md-block px-3 px-md-0">
            <v-img
              :aspect-ratio="16 / 9"
              :width="imageWidth"
              src="~@/assets/illustrations/illmoy-03.jpg"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="text-wrapper fill-height d-flex flex-column align-center justify-center px-7">
            <p class="mt-5 text-center text-md-start">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
            illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum
            cumque maxime modi ea, earum odit sunt natus beatae consectetur?
          </p>
          <p class="mt-5 text-center text-md-start">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
            debitis architecto at dicta laudantium iure ullam eos quam est velit
            rerum enim in perspiciatis porro aliquam voluptas aperiam. Sapiente,
            corrupti! Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Nihil consectetur quis minima voluptates accusantium non officia
            esse expedita facere, maiores atque voluptatibus minus dolore
            itaque, fugit fugiat doloremque temporibus nam.
          </p>
          </div>
        </v-col>
        
        <v-row style="margin: 5rem 0">
          <v-col cols="12" md="5">
          <div class="text-wrapper fill-height d-flex flex-column align-center justify-center px-7">
            <p class="mt-5 text-center text-md-start">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
            illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum
            cumque maxime modi ea, earum odit sunt natus beatae consectetur?
          </p>
          <p class="mt-5 text-center text-md-start">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
            debitis architecto at dicta laudantium iure ullam eos quam est velit
            rerum enim in perspiciatis porro aliquam voluptas aperiam. Sapiente,
            corrupti! Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Nihil consectetur quis minima voluptates accusantium non officia
            esse expedita facere, maiores atque voluptatibus minus dolore
            itaque, fugit fugiat doloremque temporibus nam.
          </p>
          </div>
        </v-col>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="5">
          <div class="text-wrapper fill-height d-flex flex-column align-center justify-center px-7">
            <p class="mt-5 text-center text-md-start">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
            illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum
            cumque maxime modi ea, earum odit sunt natus beatae consectetur?
          </p>
          <p class="mt-5 text-center text-md-start">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
            debitis architecto at dicta laudantium iure ullam eos quam est velit
            rerum enim in perspiciatis porro aliquam voluptas aperiam. Sapiente,
            corrupti! Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Nihil consectetur quis minima voluptates accusantium non officia
            esse expedita facere, maiores atque voluptatibus minus dolore
            itaque, fugit fugiat doloremque temporibus nam.
          </p>
          </div>
        </v-col>
        </v-row>
      </v-row>
    </v-container>
    <reservation-bar class="mt-10" />
  </v-sheet>
</template>

<script>
import ReservationBar from "../../components/public/ReservationBar.vue";
export default {
  components: { ReservationBar },
  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200px";
        case "sm":
          return "400px";
        case "md":
          return "500px";
        case "lg":
          return "700px";
        case "xl":
          return "800px";
      }
    },
  },
};
</script>

<style>
.w-75 {
  width: 75%;
}

.w-35 {
  width: 35%;
}
</style>
